/* alumniCard.css */

.alumniCard {
    display: flex;
    /* align-items: center; */
    /* border: 1px solid #ccc; */
    /* border-radius: 8px; */
    margin: 10px auto; /* Center horizontally */
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 40vw;
    overflow-x: hidden;
    height: 50vh;
    /* overflow-y: hidden; */
}

.alumniCardLeft {
    flex: 1;
    padding-right: 16px;
    position: relative; /* Add this to make the image fill the container */
}

.alumniCardLeft img {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    object-fit: cover;
    height: 50vh;
    min-width: 15vw;
}

.alumniCardRight {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: auto;
}

.alumniName {
    font-size: 35px;
    font-weight: bold;
    color: #000;
    font-family: Abhaya Libre ExtraBold;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-top: 20px;
}

.alumniRole {
    color: #D9D9D9;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    padding-top: 10px;
}

.alumniResponse {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #000;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.alumniCompanies {
    display: flex;
    align-items: center;
}

.alumniCompanies img {
    max-width: 7rem;
    max-height: 5rem;
    margin-right: 8px;
    padding-top: 1rem;
    padding-bottom: 20px;
}

@media (max-width: 900px) {
    .alumniResponse {
        font-size: 14px;
    }

    .alumniRole {
        font-size: 14px;
    }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .alumniCard {
        width: 70vw;
        height: 45vh;
    }

    .alumniCardLeft {
        padding-right: 0; /* Remove right padding */
    }

    .alumniCardRight {
        flex: 1; /* Take full width on smaller screens */
    }

    .alumniCompanies img {
        max-width: 100%; /* Make images responsive */
    }

    .alumniName {
        font-size: 16px; /* Adjust the font size for the name on mobile */
    }

    .alumniRole {
        font-size: 12px; /* Adjust the font size for the role on mobile */
    }

    .alumniResponse {
        font-size: 10px; /* Adjust the font size for the response on mobile */
    }

    .alumniCompanies img {
        max-width: 3rem; /* Adjust the maximum width for company logos on mobile */
        max-height: 4rem; /* Adjust the maximum height for company logos on mobile */
        margin-right: 8px;
        padding-top: 8px;
    }

}