.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  backdrop-filter: blur(1px);
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  height: 90%;
  width: 90%;
  background-color: #faf9f6;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.portraitContainer {
  width: 29%;
  overflow: hidden;
}

.portrait {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.portrait img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.card h1,
.card h2 {
  font-family: "Abhaya Libre", serif;
}

.nameSection h3 {
  font-weight: 300;
  color: #b8b8b8;
  font-size: 1.15vw;
}

.nameSection h1 {
  color: #891a1f;
  font-size: 2.5vw;
  margin-top: 1%;
  margin-bottom: 1%;
}

.nameSection #pronouns {
  font-style: italic;
}

.responseSection h2 {
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 1.8vw;
  font-weight: 500;
}

.responseSection h3 {
  font-size: 1.15vw;
  font-weight: 300;
  font-style: italic;
}

.infoContainer {
  padding: 3%;
  width: 71%;
  overflow: auto;
}

#closeCard {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  padding: 0;
  z-index: 1001;
  fill: #212427;
}

#closeCard:hover {
  fill: #891a1f;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 950px) {
  .cardContainer {
    flex-direction: column;
    width: 90%;
    height: 80%;
    overflow: auto;
  }
  .portraitContainer {
    width: 100%;
    height: 100%;
  }
  .portrait {
    display: block;
  }
  .portrait img {
    width: 100%;
    height: auto;
    transform: scale(1.1);
  }
  .infoContainer {
    width: 100%;
    height: 100%;
    padding: 15px;
  }
  .nameSection h3 {
    font-size: 4.15vw;
  }

  .nameSection h1 {
    font-size: 6.5vw;
  }

  .responseSection h2 {
    font-size: 5.8vw;
  }

  .responseSection h3 {
    font-size: 4.15vw;
  }
}
